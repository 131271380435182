import pro1 from "../Images/I1.png"


const ProjectCardData=[
{
imgsrc:pro1,
title:"Ecommerce Website Design",
text:"",
view:"",
},
]

export default ProjectCardData;